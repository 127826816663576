<template>
  <div v-if="isOpen" class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
      <button class="close-button" @click="closeModal">&times;</button>
      <h1 class="title">Last step!</h1>
      <p class="reminder">Reminder: apply the code</p>
      <p class="promo-code">FALL2024</p>
      <p class="fee-waiver">to waive our $149 fee.</p>
      <p class="no-credit-card">NO CREDIT CARD OR PERSONAL INFORMATION REQUIRED</p>
      <div class="fee-box">

      <img class="fee-image" src="@/assets/images/stripe-screenshot.png" alt="fee waiver" />
      </div>
      <p class="completion-note">
        Once you complete the survey, your two Style Boards (with your
        customized furniture options) will be sent to you within 72 hours.
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const isOpen = ref(false)

const openModal = () => {
  isOpen.value = true
}

const closeModal = () => {
  isOpen.value = false
}

onMounted(() => {
  openModal()
})
</script>

<style scoped lang="scss">
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: "Sohne", sans-serif;
}

.modal-content {
  background-color: #fcfbd7;
  padding: 2rem 4rem;
  border-radius: 10px;
  max-width: 600px;
  width: 90%;
  text-align: center;
  position: relative;
  font-weight: bold;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 0;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.reminder {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.promo-code {
  font-size: 2rem;
  color: #4a90e2;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.fee-waiver {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.no-credit-card {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  margin-top: 20px;
  font-weight: normal;
}

.fee-box {
  background-color: #064e3b;
  border-radius: 70px;
  overflow: hidden;
  margin: 40px auto;
  max-width: 90%;
  aspect-ratio: 2.5 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.fee-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.completion-note {
  font-size: 0.9rem;
  margin-top: 1rem;
  font-weight: normal;
}
</style>
