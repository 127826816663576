<template>
  <main class="main page newpagechnge quizStep firstStepSliderPaddingBottom">
    <div class="quiz quiz-registration">
      <div class="quiz-content">
        <template v-if="quizData">
          <QuizProgressBar :reachedCheckpoints="currentProgressCheckpoint"
                           :labels="['The Basics','The Style','The Specs','Finish']"/>
          <transition name="fade" mode="out-in">
            <!--
               ______   _______  _______ _________ _______  _______
              (  ___ \ (  ___  )(  ____ \\__   __/(  ____ \(  ____ \
              | (   ) )| (   ) || (    \/   ) (   | (    \/| (    \/
              | (__/ / | (___) || (_____    | |   | |      | (_____
              |  __ (  |  ___  |(_____  )   | |   | |      (_____  )
              | (  \ \ | (   ) |      ) |   | |   | |            ) |
              | )___) )| )   ( |/\____) |___) (___| (____/\/\____) |
              |/ \___/ |/     \|\_______)\_______/(_______/\_______)
            -->
            <div v-if="activeSection?.id === 'basics'">

              <transition name="fade" mode="out-in">
                <QuizStepWrapper
                  v-if="activeStep === 0"
                  key="furniture-type"
                  :next-step="nextStep"
                  :prev-step="prevStep"
                  :last-step="false"
                  :prev-btn-disabled="true"
                >
                  <!-- Type of Furniture -->
                  <transition name="fade" mode="out-in">
                    <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                  </transition>
                  <RadioTt
                    v-model="responses.type_of_furniture"
                    :question="quizData.type_of_furniture"
                    ref="q-0"
                    required
                    @change="changeFurnitureType"
                  />
                  <transition name="fade" mode="out-in">
                    <!-- Living room furniture -->
                    <Checkbox
                      v-if="responses?.type_of_furniture === 'Living room'"
                      v-model="responses.replace_living_room"
                      :question="quizData.replace_living_room"
                      ref="q-1"
                      required
                    />
                    <!-- Bedroom furniture -->
                    <Checkbox
                      v-else-if="responses?.type_of_furniture === 'Bedroom'"
                      v-model="responses.replace_bedroom"
                      :question="quizData.replace_bedroom"
                      ref="q-2"
                      required
                    />
                    <!-- Dining room furniture -->
                    <Checkbox
                      v-else-if="responses?.type_of_furniture === 'Dining room'"
                      v-model="responses.replace_dining_room"
                      :question="quizData.replace_dining_room"
                      ref="q-3"
                      required
                    />
                    <!-- Office furniture -->
                    <Checkbox
                      v-else-if="responses?.type_of_furniture === 'Office'"
                      v-model="responses.replace_office"
                      :question="quizData.replace_office"
                      ref="q-4"
                      required
                    />
                  </transition>
                </QuizStepWrapper>

                <!-- Timeline -->
                <QuizStepWrapper
                  v-else-if="activeStep === 1"
                  key="timeline"
                  :next-step="nextStep"
                  :prev-step="prevStep"
                  :last-step="false"
                >
                  <transition name="fade" mode="out-in">
                    <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                  </transition>
                  <RadioTt
                    v-model="responses.your_timeline"
                    :question="quizData.your_timeline"
                    ref="q-0"
                    required
                    :menu-type="'timeline'"
                  />
                  <!-- Timeline notes -->
                  <TextAreaTt
                    v-model="responses.timeline_notes"
                    :question="quizData.timeline_notes"
                  />
                </QuizStepWrapper>
                <transition name="fade" mode="out-in" class="basic-user-data" v-show="!userLoggedIn"
                            v-else-if="activeStep === 2 ">
                  <QuizStepWrapper
                                   :next-step="nextStep"
                                   :prev-step="prevStep"
                                   :hide-navigation="true"
                  >
                    <transition name="fade" mode="out-in">
                      <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                    </transition>
                    <HeadingTt textAlign="center">
                      We like to get familiar with our clients (and it’s how we save your answers!)
                    </HeadingTt>
                    <RegistrationNew v-model="responses.userData" ref="UserRegistration" required :next-step="nextStep" :prev-step="prevStep" :set-page-loading="(value) => loading=value"/>

                    <!-- <div class="imageGrid"><SocialPosts /></div> -->
                  </QuizStepWrapper>
                </transition>
                <!--
                   _______ _________          _        _______
                  (  ____ \\__   __/|\     /|( \      (  ____ \
                  | (    \/   ) (   ( \   / )| (      | (    \/
                  | (_____    | |    \ (_) / | |      | (__
                  (_____  )   | |     \   /  | |      |  __)
                        ) |   | |      ) (   | |      | (
                  /\____) |   | |      | |   | (____/\| (____/\
                  \_______)   )_(      \_/   (_______/(_______/
                -->
                <QuizStepWrapper
                  v-else-if="activeStep === 3"
                  key="favorite-styles"
                  :next-step="nextStep"
                  :prev-step="prevStep"
                >
                  <transition name="fade" mode="out-in">
                    <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                  </transition>
                  <!-- Style selector -->
                  <HeadingTt textAlign="center">
                    Pick the overall style/look you prefer for your room.
                  </HeadingTt>
                  <StyleSelector
                    :typeOfFurniture="responses.type_of_furniture"
                    v-model="responses.favorite_styles"
                    required
                    ref="q-0"
                  />
                </QuizStepWrapper>

                <!-- Wood tone -->
                <QuizStepWrapper
                  v-else-if="activeStep === 4"
                  key="wood-tone"
                  :next-step="nextStep"
                  :prev-step="prevStep"
                >
                  <transition name="fade" mode="out-in">
                    <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                  </transition>
                  <HeadingTt>
                    Which wood tone do you prefer?
                  </HeadingTt>
                  <StyleRadio
                    v-model="responses.wood_tone"
                    :options="quizData.wood_tone_options"
                    required
                    autoProceed
                    ref="q-0"
                  />
                </QuizStepWrapper>
              </transition>
            </div>

            <!--
               _     _       _              ______
              | |   (_)     (_)             | ___ \
              | |    ___   ___ _ __   __ _  | |_/ /___   ___  _ __ ___
              | |   | \ \ / / | '_ \ / _` | |    // _ \ / _ \| '_ ` _ \
              | |___| |\ V /| | | | | (_| | | |\ \ (_) | (_) | | | | | |
              \_____/_| \_/ |_|_| |_|\__, | \_| \_\___/ \___/|_| |_| |_|
                                      __/ |
                                     |___/
            -->
            <div v-else-if="activeSection?.id === 'living-room'">
              <transition name="fade" mode="out-in">
                <QuizStepWrapper
                  v-if="activeStep === 0"
                  key="sofa-style"
                  :next-step="nextStep"
                  :prev-step="prevStep"
                >
                  <transition name="fade" mode="out-in">
                    <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                  </transition>
                  <!-- Sofa style -->
                  <HeadingTt>
                    Which of the 3 sofa styles suits you best?
                  </HeadingTt>
                  <StyleRadio
                    v-model="responses.sofa_style"
                    :options="quizData.sofa_options"
                    required
                    autoProceed
                    ref="q-0"
                    @radioClicked="handleRadioClicked"
                  />
                </QuizStepWrapper>

                <QuizStepWrapper
                  v-else-if="activeStep === 1"
                  key="sofa-fabric"
                  :next-step="nextStep"
                  :prev-step="prevStep"
                >
                  <transition name="fade" mode="out-in">
                    <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                  </transition>
                  <!-- Sofa fabric -->
                  <RadioTt
                    v-model="responses.sofa_fabric"
                    :question="quizData.sofa_fabric"
                    ref="q-0"
                    required
                    autoProceed
                    @radioClicked="handleRadioClicked"
                  />
                </QuizStepWrapper>

                <QuizStepWrapper
                  v-else-if="activeStep === 2"
                  key="sofa-type"
                  :next-step="nextStep"
                  :prev-step="prevStep"
                >
                  <transition name="fade" mode="out-in">
                    <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                  </transition>
                  <!-- Specific type of sofa -->
                  <RadioTt
                    v-model="responses.type_of_sofa"
                    :question="quizData.type_of_sofa"
                    ref="q-0"
                    required
                    typeName="type_of_sofa"
                    @radioClicked="handleRadioClicked"
                  />
                  <!-- Sofa notes -->
                  <TextAreaTt
                    v-model="responses.sofa_notes"
                    :question="quizData.sofa_notes"
                  />
                </QuizStepWrapper>
              </transition>
            </div>

            <!--
              ______          _
              | ___ \        | |
              | |_/ / ___  __| |_ __ ___   ___  _ __ ___
              | ___ \/ _ \/ _` | '__/ _ \ / _ \| '_ ` _ \
              | |_/ /  __/ (_| | | | (_) | (_) | | | | | |
              \____/ \___|\__,_|_|  \___/ \___/|_| |_| |_|
            -->
            <div v-else-if="activeSection?.id === 'bedroom'">
              <transition name="fade" mode="out-in">
                <!-- Bed style -->
                <QuizStepWrapper
                  v-if="activeStep === 0"
                  key="favorite-bed"
                  :next-step="nextStep"
                  :prev-step="prevStep"
                >
                  <transition name="fade" mode="out-in">
                    <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                  </transition>
                  <HeadingTt>
                    Which of the three bed styles is your favorite?
                  </HeadingTt>
                  <StyleRadio
                    v-model="responses.bed_style"
                    :options="quizData.bed_options"
                    required
                    autoProceed
                    ref="q-0"
                    @radioClicked="handleRadioClicked"
                  />
                </QuizStepWrapper>

                <!-- What size bed -->
                <QuizStepWrapper
                  v-else-if="activeStep === 1"
                  key="bed-size"
                  :next-step="nextStep"
                  :prev-step="prevStep"
                >
                  <transition name="fade" mode="out-in">
                    <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                  </transition>
                  <!-- <ImageTt url="/img/cat.jpg" classes="centered" :config="{customCss: 'position: relative; top: 3em', altTag: 'Image of a cat'}" /> -->
                  <RadioTt
                    v-model="responses.bed_size"
                    :question="quizData.bed_size"
                    ref="q-0"
                    required
                    autoProceed
                    @radioClicked="handleRadioClicked"
                  />
                </QuizStepWrapper>

                <!-- Interested in anything else? -->
                <QuizStepWrapper v-else-if="activeStep === 2" key="other-pieces-bedroom"
                                 :next-step="nextStep"
                                 :prev-step="prevStep">
                  <transition name="fade" mode="out-in">
                    <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                  </transition>
                  <ImageTt url="/img/plants.jpg" classes="centered--lower3em" :config="{altTag: 'Image of Plants'}"/>
                  <Checkbox
                    v-model="responses.other_pieces_bedroom"
                    :question="quizData.other_pieces_bedroom"
                    ref="q-0"
                    required
                  />
                </QuizStepWrapper>
              </transition>
            </div>

            <!--
              ______ _       _              ______
              |  _  (_)     (_)             | ___ \
              | | | |_ _ __  _ _ __   __ _  | |_/ /___   ___  _ __ ___
              | | | | | '_ \| | '_ \ / _` | |    // _ \ / _ \| '_ ` _ \
              | |/ /| | | | | | | | | (_| | | |\ \ (_) | (_) | | | | | |
              |___/ |_|_| |_|_|_| |_|\__, | \_| \_\___/ \___/|_| |_| |_|
                                      __/ |
                                     |___/
            -->
            <div v-else-if="activeSection?.id === 'dining-room'">
              <transition name="fade" mode="out-in">
                <QuizStepWrapper v-if="activeStep === 0" key="dining-room-number"
                                 :next-step="nextStep"
                                 :prev-step="prevStep">
                  <transition name="fade" mode="out-in">
                    <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                  </transition>
                  <!-- How many people should dining room accomodate -->
                  <ImageTt url="/img/vases.jpg" classes="centered--lower3em"/>
                  <RadioTt
                    v-model="responses.dining_room_number_of_people"
                    :question="quizData.dining_room_number_of_people"
                    ref="q-0"
                    required
                    autoProceed
                    @radioClicked="handleRadioClicked"
                  />
                  <transition name="fade">
                    <TextInput
                      v-if="responses.dining_room_number_of_people === 'Other'"
                      v-model="responses.dining_room_number_of_people_other"
                      :question="{ question: 'Please enter the number below.' }"
                      fieldType="number"
                      min="0"
                      ref="q-1"
                      required
                    />
                  </transition>
                </QuizStepWrapper>
                <QuizStepWrapper v-else-if="activeStep === 1" key="table-height"
                                 :next-step="nextStep"
                                 :prev-step="prevStep">
                  <transition name="fade" mode="out-in">
                    <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                  </transition>
                  <HeadingTt>
                    Would you like a bistro table or a standard height table?
                  </HeadingTt>
                  <StyleRadio
                    v-model="responses.table_height"
                    :options="quizData.table_height"
                    ref="q-0"
                    required
                    autoProceed
                    @radioClicked="handleRadioClicked"
                  />
                </QuizStepWrapper>
                <QuizStepWrapper v-else-if="activeStep === 2" key="bar-height"
                                 :next-step="nextStep"
                                 :prev-step="prevStep">
                  <transition name="fade" mode="out-in">
                    <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                  </transition>
                  <RadioTt
                    v-model="responses.existing_bar_height"
                    :question="quizData.existing_bar_height"
                    ref="q-0"
                    required
                    @radioClicked="handleRadioClicked"
                  />
                  <TextInput
                    v-model="responses.number_of_stools_needed"
                    :question="quizData.number_of_stools_needed"
                    fieldType="number"
                    ref="q-1"
                    min="0"
                    required
                  />
                </QuizStepWrapper>
              </transition>
            </div>

            <!--
               _____  __  __ _
              |  _  |/ _|/ _(_)
              | | | | |_| |_ _  ___ ___
              | | | |  _|  _| |/ __/ _ \
              \ \_/ / | | | | | (_|  __/
               \___/|_| |_| |_|\___\___|
            -->
            <div v-else-if="activeSection?.id === 'office'">
              <transition name="fade" mode="out-in">
                <QuizStepWrapper v-if="activeStep === 0" key="how-tall"
                                 :next-step="nextStep"
                                 :prev-step="prevStep">
                  <TextAreaTt
                    v-model="responses.how_tall_are_you"
                    :question="quizData.how_tall"
                    ref="q-0"
                    required
                  />
                </QuizStepWrapper>
                <QuizStepWrapper v-else-if="activeStep === 1" key="chair-type"
                                 :next-step="nextStep"
                                 :prev-step="prevStep">
                  <RadioTt
                    v-model="responses.chair_type"
                    :question="quizData.chair_type"
                    ref="q-0"
                    required
                    autoProceed
                  />
                </QuizStepWrapper>
                <QuizStepWrapper v-else-if="activeStep === 2" key="other-pieces-office"
                                 :next-step="nextStep"
                                 :prev-step="prevStep">
                  <!-- Additional office pieces -->
                  <Checkbox
                    v-model="responses.other_pieces_office"
                    :question="quizData.other_pieces_office"
                    ref="q-0"
                    required
                  />
                </QuizStepWrapper>
                <QuizStepWrapper
                  v-else-if="activeStep === 3"
                  key="sofa-style"
                  :next-step="nextStep"
                  :prev-step="prevStep"
                >
                  <!-- Sofa style office -->
                  <HeadingTt>
                    Which of the 3 sofa styles suits you best?
                  </HeadingTt>
                  <StyleRadio
                    v-model="responses.sofa_style"
                    :options="quizData.sofa_options"
                    required
                    ref="q-0"
                    autoProceed
                  />
                </QuizStepWrapper>

                <QuizStepWrapper
                  v-else-if="activeStep === 4"
                  key="sofa-fabric"
                  :next-step="nextStep"
                  :prev-step="prevStep"
                >
                  <!-- Sofa fabric -->
                  <RadioTt
                    v-model="responses.sofa_fabric"
                    :question="quizData.sofa_fabric"
                    ref="q-0"
                    required
                    autoProceed
                  />
                </QuizStepWrapper>
              </transition>
            </div>

            <!--
               _____ _           _
              /  __ \ |         (_)
              | /  \/ |__   __ _ _ _ __ ___
              | |   | '_ \ / _` | | '__/ __|
              | \__/\ | | | (_| | | |  \__ \
               \____/_| |_|\__,_|_|_|  |___/
            -->
            <div v-else-if="activeSection?.id === 'chairs'">
              <transition name="fade" mode="out-in">
                <!-- Chair style -->
                <QuizStepWrapper
                  v-if="activeStep === 0"
                  key="chair-style"
                  :next-step="nextStep"
                  :prev-step="prevStep"
                >
                  <transition name="fade" mode="out-in">
                    <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                  </transition>
                  <HeadingTt textAlign="center">
                    Which style of chair are you more drawn to?
                  </HeadingTt>
                  <StyleRadio
                    v-model="responses.chair_style"
                    :options="quizData.chair_options"
                    required
                    autoProceed
                    ref="q-0"
                    @radioClicked="handleRadioClicked"
                  />
                </QuizStepWrapper>

                <QuizStepWrapper
                  v-else-if="activeStep === 1"
                  key="chair-fabric"
                  :next-step="nextStep"
                  :prev-step="prevStep"
                >
                  <transition name="fade" mode="out-in">
                    <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                  </transition>
                  <!-- Chair fabric -->
                  <RadioTt
                    v-model="responses.chair_fabric"
                    :question="quizData.chair_fabric"
                    required
                    autoProceed
                    ref="q-0"
                    @radioClicked="handleRadioClicked"
                  />
                </QuizStepWrapper>
              </transition>
            </div>

            <div v-else-if="activeSection?.id === 'finishing-up'">
              <transition name="fade" mode="out-in">
                <QuizStepWrapper v-if="activeStep === 0 && !quizComplete" key="rug-style"
                                 :next-step="nextStep"
                                 :prev-step="prevStep">
                  <transition name="fade" mode="out-in">
                    <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                  </transition>
                  <HeadingTt>
                    Rugs are a great way to add some character to a space. Do any of these jump out at you?
                  </HeadingTt>
                  <StyleRadioSlides
                    v-model="responses.rug_style"
                    ref="q-0"
                    class="slider_new"
                  />
                </QuizStepWrapper>

                <QuizStepWrapper v-else-if="activeStep === 1" key="color-importance"
                                 :next-step="nextStep"
                                 :prev-step="prevStep">
                  <transition name="fade" mode="out-in">
                    <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                  </transition>
                  <Slider
                    v-model="responses.color_importance"
                    :question="quizData.color_importance"
                  />
                  <div class="form__image">
                    <div class="form__content">
                      <figure class="imgCHO" style="grid-column: span 7;"><img
                        :src="require('@/assets/images/c-1.webp')"
                        :alt="'Example project image'"
                      ></figure>
                      <figure class="imgCHO" style="grid-column: span 5;">
                        <img :src="require('@/assets/images/c-2.webp')"
                             :alt="'Example project image'"
                        >
                      </figure>
                    </div>
                  </div>
                  <transition name="fade">
                    <Checkbox
                      v-if="responses.color_importance > 4"
                      v-model="responses.favorite_color"
                      :question="quizData.favorite_color"
                      required
                      :showSpecialElementsKey=true
                      ref="q-1"
                    />
                  </transition>
                </QuizStepWrapper>
                <!--
                   _______  _______  _______  _______  _______
                  (  ____ \(  ____ )(  ____ \(  ____ \(  ____ \
                  | (    \/| (    )|| (    \/| (    \/| (    \/
                  | (_____ | (____)|| (__    | |      | (_____
                  (_____  )|  _____)|  __)   | |      (_____  )
                        ) || (      | (      | |            ) |
                  /\____) || )      | (____/\| (____/\/\____) |
                  \_______)|/       (_______/(_______/\_______)
                -->
                <!-- Mix & Match tool preview -->
                <!-- <QuizStepWrapper v-else-if="activeStep === 2"
                                 :next-step="nextStep"
                                 :prev-step="prevStep">
                  <MixAndMatchTool :responses="responses" />
                </QuizStepWrapper> -->
                <!-- Room Photos -->
                <QuizStepWrapper v-else-if="activeStep === 2" key="photos"
                                 :next-step="nextStep"
                                 :prev-step="prevStep">
                  <transition name="fade" mode="out-in">
                    <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                  </transition>
                  <TextAreaTt v-model="responses.style_notes" :question="quizData.style_notes" :placeholder="`I have two golden retrievers that shed, so I'll need stain-resistant fabric.
My living room budget is $5,000.
I live in an old brownstone: should I be worried about my new furniture fitting up the stairs / in the hallways?`"/>
                  <FileUpload
                    v-model="responses.space_images"
                    ref="uploader"
                    :question="quizData.inspiration"
                    :maxFiles="4"
                  />
                  <div class="inspiration-links">
                    <TextInput
                      v-for="index in numberOfInspirationLinkInputs"
                      v-bind:key="index"
                      v-model="responses.inspirationLinks[index]"
                      :question="{ question: `Inspiration link ${index}`, placeholder: 'https://...' }"
                      variant="outlined"
                    />
                    <button class="add-more-links-button" @click="addInspirationLinksRow">+ Add more links</button>
                  </div>
                  <PhotosPreview :images="responses.space_images"
                                 :onFileRemove="(file) => $refs.uploader.remove(undefined,file.id)"/>
                </QuizStepWrapper>

                <!-- Measurements -->
                <QuizStepWrapper v-else-if="activeStep === 3"
                                 :next-step="nextStep"
                                 :prev-step="prevStep">
                  <PhotosAndMeasurements v-model="responses" ref="PhotosAndMeasurements" :rendr-invitation-id="rendrInvitationId"/>
                </QuizStepWrapper>

                <!-- Checkout -->
                <QuizStepWrapper
                  v-else-if="activeStep === 4 && userProfile?.selectionFeeOrderStatus !== 'complete' && targetSurvey"
                  :next-step="nextStep"
                  :prev-step="prevStep"
                  hide-navigation>
                  <EmbeddedCheckout :nextStep="nextStep"/>
                </QuizStepWrapper>
              </transition>
            </div>
            <!-- Quiz End -->
          </transition>
        </template>
      </div>
      <Loader :visible="loading" fixed/>
      <div v-if="initialLoad" class="quiz__loader-cover"/>

      <transition name="fade">
        <div v-if="showInProgressDialog" class="quiz__in-progress-overlay">
          <h1 class="quiz__heading">Survey in Progress</h1>
          <div class="form__question">
            <div class="form__content">
              <p class="quiz__subheading">You started a survey already; would you like to continue it, or start
                a new one?</p>
              <div class="form__actions">
                <button class="primary customGreenBTN" @click="continueInProgressQuiz">
                  Continue Survey
                </button>
                <button class="block btn-Border" @click="showInProgressDialog = false">
                  Start New Project
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showDebug" class="button--debug">
      <a @click="validate = !validate">Toggle Validation | Currently Active?: {{ validate }}</a>
    </div>
  </main>

</template>

<script>
import * as quizData from '@/components/quiz/quizDataNew'
import QuizStepWrapper from '@/components/quiz/QuizStepWrapper'
import Loader from '@/components/Loader'
// import { httpsCallable } from 'firebase/functions'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { nextTick } from 'vue'
import ImageTt from '../components/quiz/questions/ImageTt.vue'
import RegistrationNew from '../components/quiz/questions/RegistrationNew.vue'
import RadioTt from '@/components/quiz/questions/RadioTt.vue'
import Checkbox from '@/components/quiz/questions/Checkbox.vue'
import HeadingTt from '@/components/quiz/questions/HeadingTt.vue'
import TextAreaTt from '@/components/quiz/questions/TextAreaTt.vue'
import StyleSelector from '@/components/quiz/questions/StyleSelector.vue'
import StyleRadio from '@/components/quiz/questions/StyleRadio.vue'
import TextInput from '@/components/quiz/questions/TextInput.vue'
import StyleRadioSlides from '@/components/quiz/questions/StyleRadioSlides.vue'
import Slider from '@/components/quiz/questions/Slider.vue'
import FileUpload from '@/components/quiz/questions/FileUpload.vue'
import QuizProgressBar from '@/components/quiz/QuizProgressBar.vue'
// import StyleBoardsSneakPeak from '@/components/quiz/StyleBoardsSneakPeak.vue'
import PhotosAndMeasurements from '@/components/quiz/PhotosAndMeasurements/PhotosAndMeasurements.vue'
import EmbeddedCheckout from '@/components/quiz/EmbeddedCheckout.vue'
import PhotosPreview from '@/views/PhotosPreview.vue'
// import MixAndMatchTool from '@/components/MixAndMatchTool.vue'

export default {
  name: 'Quiz',
  components: {
    // MixAndMatchTool,
    PhotosPreview,
    EmbeddedCheckout,
    PhotosAndMeasurements,
    // StyleBoardsSneakPeak,
    QuizProgressBar,
    QuizStepWrapper,
    Loader,
    ImageTt,
    RegistrationNew,
    RadioTt,
    Checkbox,
    HeadingTt,
    TextAreaTt,
    StyleSelector,
    StyleRadio,
    TextInput,
    StyleRadioSlides,
    Slider,
    FileUpload
  },
  data () {
    return {
      isModalVisible: false,
      activeStep: 0,
      activeSectionIndex: 0,
      projectName: '',
      projectAddress: {},
      photosRoom: null,
      floorPlans: null,
      spaceimages: null,
      rendrInvitationId: null,
      photos: {
        photosRoom: null,
        floorPlans: null
      },
      responses: {
        userData: {
          first_name: '',
          email: ''
        },
        space_images: [],
        inspirationLinks: {
          1: '',
          2: ''
        }
      },
      quizData,
      validate: true,
      initialLoad: true,
      showInProgressDialog: false,
      loading: false,
      quizComplete: false,
      surveyBtn: false,
      initialSurvey: false,
      userLoggedIn: false,
      numberOfInspirationLinkInputs: 2
    }
  },
  computed: {
    ...mapState(['firestore', 'fbFunctions', 'showDebug']),
    ...mapGetters(['currentUser', 'userProfile', 'inProgressSurveys', 'targetSurvey']),
    surveyId () {
      return this.targetSurvey?.id
    },
    currentProgressCheckpoint () {
      if (this.sectionHeading === 'The Basics') {
        return 0
      }
      if (this.sectionHeading === 'The Style') {
        return 1
      }
      if (this.sectionHeading === 'The Specs') {
        return 2
      }
      return 3
    },
    sectionHeading () {
      if (this.activeSection?.title) {
        return Array.isArray(this.activeSection.title) ? this.activeSection.title[this.activeStep] : this.activeSection.title
      } else {
        return ''
      }
    },
    // The total number of steps in the survey will vary depending on what space is chosen
    totalSteps () {
      let total = 0
      if (!this.includedSections) return total
      this.includedSections.forEach((section) => {
        total += section.steps
      })
      return total
    },
    /**
     * Computes the section "blocks" that should be included in the Quiz
     * based off of user responses.
     */
    includedSections () {
      // Basics are always present at the beginning
      const sections = [{
        id: 'basics',
        steps: 5,
        title: [
          'The Basics',
          'The Basics',
          'The Basics',
          'The Style',
          'The Style'
        ]
      }]
      // Then, conditional sections are added based on the chosen space
      if (this.responses?.type_of_furniture === 'Living room' && (this.responses?.replace_living_room?.includes('Sofa') ||
        this.responses?.replace_living_room?.includes('Nothing specific - whatever you think!'))) {
        sections.push({
          id: 'living-room',
          steps: 3,
          title: 'The Style'
        })
      }
      if (this.responses?.type_of_furniture === 'Bedroom') {
        sections.push({
          id: 'bedroom',
          steps: 3,
          title: 'The Style'
        })
      }
      if (
        this.responses?.type_of_furniture === 'Office' ||
        this.responses?.replace_living_room?.includes('Office furniture') ||
        this.responses?.other_pieces_bedroom?.includes('Office furniture')
      ) {
        sections.push({
          id: 'office',
          steps: 5,
          title: 'The Style'
        })
      }
      if (this.responses?.type_of_furniture === 'Dining room') {
        sections.push({
          id: 'dining-room',
          steps: 3,
          title: 'The Style'
        })
      }
      if (
        this.responses?.replace_living_room?.includes('Chair') ||
        this.responses?.replace_living_room?.includes('Nothing specific - whatever you think!') ||
        this.responses?.replace_bedroom?.includes('Chair') ||
        this.responses?.other_pieces_bedroom?.includes('Chair') ||
        this.responses?.other_pieces_bedroom?.includes('Chair with ottoman') ||
        this.responses?.other_pieces_office?.includes('Chair')
      ) {
        sections.push({
          id: 'chairs',
          steps: 2,
          title: 'The Style'
        })
      }
      // Finally, push the final steps that are common across all spaces
      sections.push({
        id: 'finishing-up',
        // steps: 6,
        steps: 5,
        /**
         * When uncommenting, make sure to uncommenct also:
         * mix&match tool from quiz steps and reorder next steps
         * skips with previewSecret part
         * skips with selectionFeeOrderStatus
         */
        title: [
          'The Style',
          'The Style',
          // 'The Style', // mix&match tool
          'The Style',
          'The Specs',
          'The Specs'
        ]
      })
      return sections
    },

    activeSection () {
      //
      return this.includedSections[this.activeSectionIndex] || {}
    },

    onLastStep () {
      //
      return this.activeSectionIndex + 1 === this.includedSections.length && this.activeStep + 1 === this.activeSection.steps
    },

    /**
     * Calculates the quiz progress as a %, based on the total number of
     * steps and sections completed within the calculated total number of steps.
     */
    quizProgress () {
      let activeSteps = this.activeStep
      for (let i = 0; i < this.activeSectionIndex; i++) {
        //
        activeSteps += this.includedSections[i].steps
      }
      return activeSteps / this.totalSteps * 100
    }

  },

  async created () {
    this.loading = true
    this.setTargetSurvey(null)
    await this.checkForTargetSurvey()
    this.loading = false
  },
  async mounted () {
    await nextTick()

    if (!this.targetSurvey) {
      await this.checkForTargetSurvey()
    }

    // A bit hacky, but we're just waiting for the transition to finish here if there's a target survey to load
    if (this.$route.query.id) {
      setTimeout(() => {
        this.initialLoad = false
      }, 300)
    } else {
      this.initialLoad = false
    }
  },
  methods: {
    ...mapMutations([
      'setCurrentUser',
      'setUserProfile',
      'setTargetSurvey',
      'setGlobalError',
      'setGlobalNotification'
    ]),
    ...mapActions([
      'initFirestoreSurveysData',
      'initFirestoreFromMiddelSurveysData',
      'updateFirestoreUserProfile',
      'updateFirestoreCurrentQuiz',
      'getMySurveys',
      'getTargetSurvey'
    ]),
    handleRadioClicked () {
      this.nextStep()
    },
    changeFurnitureType () {
      // If the space is changed, we don't want to keep furniture selections specific to the previous room

      delete this.responses.replace_living_room
      delete this.responses.replace_bedroom
      delete this.responses.replace_dining_room
    },

    /**
     * When we land here, we're going to check to see if we need to load any quiz,
     * or if the user has any in progress quizzes. Otherwise, we'll make a new one.
     */
    async checkForTargetSurvey () {
      // First, check the URL to see if the user is targeting a specific quiz...
      if (this.$route.query.id) {
        await this.getTargetSurvey({ id: this.$route.query.id, setState: true })
        this.loadTargetSurveyResponses()
      } else if (this.$route.query.utm_medium) {
        await this.getTargetSurvey({ id: this.$route.query.utm_medium, setState: true })
        this.loadTargetSurveyResponses()
        await this.nextStep()
      } else if (!this.$route.query.new) {
        if (this.userLoggedIn) {
          if (!this.mySurveys) {
            await this.getMySurveys()
          }
          if (this.inProgressSurveys?.length) {
            this.showInProgressDialog = true
          }
        }
      }
    },

    async continueInProgressQuiz () {
      await this.getTargetSurvey({ id: this.inProgressSurveys[0].id, setState: true })
      this.loadTargetSurveyResponses()
      this.showInProgressDialog = false
    },
    addInspirationLinksRow () {
      this.numberOfInspirationLinkInputs += 2
      for (const index in this.numberOfInspirationLinkInputs) {
        if (this.responses.inspirationLinks?.[index] === undefined) {
          this.responses.inspirationLinks[index] = ''
        }
      }
    },
    loadTargetSurveyResponses () {
      this.projectName = this.targetSurvey?.projectName || ''
      this.projectAddress = this.targetSurvey?.projectAddress || {}
      this.responses = { ...this.targetSurvey?.responses }
      // Jump to the current step
      this.activeStep = this.targetSurvey?.currentStep
      const activeSectionId = this.targetSurvey?.currentSection
      this.activeSectionIndex = this.includedSections.findIndex((section) => section.id === activeSectionId)
    },

    /**
     * Loops through all elements in the DOM and runs a validate()
     * function if it's available.
     */
    async validateStep () {
      let allValid = true
      if (!this.validate) return allValid

      for (const i in this.$refs) {
        if (this.$refs[i]?.validate) {
          if (!(await this.$refs[i].validate())) {
            // this.$refs[i].$el.scrollIntoView({ behavior: 'smooth' })
            allValid = false
          }
        }
      }

      return allValid
    },
    /**
     * This function handles any special events which need to occur after specific steps,
     * assuming it's different / more complex that the usual saving of a response to the survey responses.
     * For example, to upload user media before moving on to the next step.
     */
    async checkSpecialCases () {
      // if (this.activeSection?.id === 'basics' && this.activeStep === 2 && this.$refs.UserRegistration) {
      //   this.loading = true
      //   await this.$refs.UserRegistration.signUpStep()
      //   // await this.saveSurvey()
      //   this.loading = false
      // }
    },

    /**
     * Logic for what steps should be skipped, based on other responses.
     * GOTCHA: Note that this is currently checking equality on some plain values,
     * like "Living room". If any of these values are changed in quizDataNew.js,
     * they'll need to be updated here (Ideally would be refactored at some point to
     * to handle more regular values)
     */
    checkForSkips (direction) {
      let skip = false

      if (this.$route.query.previewSecret === 'gkL0TTAE2R355VxeRnZ886p0hcWnS6AI' && this.activeSection?.id === 'finishing-up' && (this.activeStep === 3 || this.activeStep === 4)) {
        skip = true
      }

      if (this.currentUser?.uid) {
        if (this.activeSection?.id === 'basics' && this.activeStep === 2) {
          skip = true
        }
        if (this.activeSection?.id === 'finishing-up' && this.activeStep === 4 && this.userProfile.selectionFeeOrderStatus === 'complete') {
          skip = true
        }
      }

      if (this.responses?.favorite_styles?.[0] !== 'moody') {
        if (this.activeSection?.id === 'basics' && this.activeStep === 4) {
          skip = true
        }
      }
      if (!(this.responses.replace_living_room?.includes('Rug') || this.responses.replace_living_room?.includes('Nothing specific - whatever you think!') || this.responses.replace_dining_room?.includes('Rug') || this.responses.replace_dining_room?.includes('Nothing specific - whatever you think!') || this.responses.replace_bedroom?.includes('Rug') || this.responses.replace_bedroom?.includes('Nothing specific - whatever you think!'))) {
        if (this.activeSection?.id === 'finishing-up' && this.activeStep === 0) {
          skip = true
        }
      }

      // If the user didn't choose "stools", this question shouldn't show
      if (!(this.responses.replace_dining_room?.includes('Stools') || this.responses.replace_dining_room?.includes('Nothing specific - whatever you think!'))) {
        if (this.activeSection?.id === 'dining-room' && this.activeStep === 2) {
          skip = true
        }
      }
      if (!(this.responses.replace_dining_room?.includes('Dining chairs') || this.responses.replace_dining_room?.includes('Dining table') || this.responses.replace_dining_room?.includes('Nothing specific - whatever you think!'))) {
        if (this.activeSection?.id === 'dining-room' && this.activeStep === 0) {
          skip = true
        }
      }

      if (!(this.responses.replace_dining_room?.includes('Dining chairs') || this.responses.replace_dining_room?.includes('Dining table') || this.responses.replace_dining_room?.includes('Nothing specific - whatever you think!')) || this.responses.dining_room_number_of_people === '6+' || this.responses.dining_room_number_of_people === 'Other') {
        if (this.activeSection?.id === 'dining-room' && this.activeStep === 1) {
          skip = true
        }
      }

      if (!(this.responses.replace_bedroom?.includes('Bed') || this.responses.replace_bedroom?.includes('Nothing specific - whatever you think!'))) {
        if (this.activeSection?.id === 'bedroom' && (this.activeStep === 0 || this.activeStep === 1)) {
          skip = true
        }
      }

      if (skip) {
        if (direction === 'next') {
          this.nextStep()
        } else {
          this.prevStep()
        }
      }
      return skip
    },
    async saveSurvey () {
      const payload = {
        responses: this.responses,
        currentSection: this.activeSection.id ?? 'COMPLETED',
        currentStep: this.activeStep,
        inProgress: this.quizProgress !== 100,
        currentProgress: this.quizProgress,
        isPreview: this.$route.query.previewSecret === 'gkL0TTAE2R355VxeRnZ886p0hcWnS6AI' && !this.activeSection.id
      }
      if (this.currentUser?.uid) {
        await this.updateFirestoreCurrentQuiz(payload)
      }
    },
    async nextStep () {
      this.loading = true
      this.surveyBtn = true
      if (this.activeStep < this.activeSection.steps) {
        // Before incrementing and moving to the next step, perform validation
        // and check for any special events
        if (!(await this.validateStep())) {
          this.surveyBtn = false
          this.loading = false
          return
        }

        try {
          await this.checkSpecialCases()
        } catch (err) {
          this.setGlobalError(err.message)
          // If there's a file uploader on the page, set the error.
          if (this.$refs.uploader) {
            this.$refs.uploader.setError('Sorry, there was an error during image upload.')
          }
          this.loading = false
          this.surveyBtn = false
          return
        }
        this.activeStep++
      }

      // The active step is equal to the number of steps in the section, time to reset.
      if (this.activeStep >= this.activeSection.steps) {
        // If the activeSectionIndex + 1 is equal to the total number of sections, we just finished
        if (this.activeSectionIndex + 1 === this.includedSections.length) {
          this.quizComplete = true
        }

        this.activeStep = 0
        this.activeSectionIndex++
      }
      // After incrementing, check to see if we should skip the slide that's about to load
      const skipping = this.checkForSkips('next')
      // If we're not skipping, scroll up and save the quiz progress
      if (!skipping) {
        window.scrollTo(0, 0)
        await this.saveSurvey()
        if (this.$gtm?.enabled()) {
          this.$gtm.trackEvent({
            event: 'quiz_step_view',
            category: 'Quiz',
            action: 'Step View',
            label: `${this.activeSection.id} - Step ${this.activeStep + 1}`,
            sectionId: this.activeSection.id,
            stepNumber: this.activeStep + 1
          })
        }
      }
      if (this.activeSection) {
        if (this.currentUser?.uid === undefined && (this.activeSection.id === 'finishing-up' && this.activeStep === 4)) {
          this.activeStep = 3
        }
      }
      // After incrementing the step, if we're 'done', then mark the quiz
      if (this.activeSectionIndex === this.includedSections.length) {
        await this.updateFirestoreCurrentQuiz({
          inProgress: false,
          isPending: true
        })
        if (this.$route.query.previewSecret === 'gkL0TTAE2R355VxeRnZ886p0hcWnS6AI') this.$router.push('/quiz-complete?previewSecret=gkL0TTAE2R355VxeRnZ886p0hcWnS6AI')
        else this.$router.push('/quiz-complete')
      }
      this.surveyBtn = false
      this.loading = false
    },

    /**
     * Decrements the current step, and falls back to the
     * previous section, if applicable.
     */
    prevStep () {
      // If either active steps or active sections have been incremented, we'll decrement
      if (this.activeStep > 0 || this.activeSectionIndex > 0) {
        setTimeout(async () => {
          const basicUserDataChild = document.querySelector('.basic-user-data')
          if (basicUserDataChild) {
            basicUserDataChild.classList.remove('basic_registration')
            basicUserDataChild.classList.add('hide_basic_registration')
          }
        }, 500)
        this.activeStep--
        if (this.activeStep < 0) {
          // Decrement the active section
          this.activeSectionIndex--
          // Reset the active step to the last in the previous section
          this.activeStep = this.activeSection.steps - 1
        }
        window.scrollTo(0, 0)
      }
      this.checkForSkips()
    }
  }
}
</script>

<style lang="scss" scoped>
.quiz {
  &__loader-cover {
    @include fill-parent;
    z-index: 100;
    background-color: white;
  }

  &__in-progress-overlay {
    @include fill-parent;
    padding: calc(var(--leading) * 2) var(--gutter);
    background-color: white;

    .form {
      &__actions {
        margin-top: var(--leading);
        grid-column: span 12;

        button {
          width: calc(50% - (var(--gutter) / 2));
          white-space: nowrap;
        }
      }
    }
  }
}

.long-loader {
  text-align: center;
  position: absolute;
  top: 154px;
  width: 100%;
  height: calc(100vh - 250px);
  background-color: $tt-bright-white;
  padding: calc(var(--leading) * 2) 0;

  @include breakpoint($s) {
    top: 114px;
    height: calc(100vh - 200px); // see form__question height in Payment.vue
  }

  &__content {
    @include meta-text;
    max-width: 300px;
    margin: 0 auto;

    @include breakpoint($s) {
      max-width: 200px;
    }

    img {
      width: 150px;

      @include breakpoint($s) {
        width: 100px;
      }
    }

    p {
      padding: var(--leading) 0;
    }
  }
}

@media (max-width: 583px) {
  .quiz .quiz__navigation__button {
    height: 40px !important;
    padding: 10px !important;
  }
}

.inspiration-links {
  grid-column-start: 4;
  grid-column-end: 10;
  display: flex;
  flex-flow: row wrap;
  gap: 10px;

  * {
    flex: 0 1 calc(50% - 10px);
  }
}

.add-more-links-button {
  margin-left: auto;
  padding: 5px 10px !important;
  background-color: transparent !important;
  border: none;
  color: #004021;
  font-size: 14px;
  text-transform: none;
  font-family: "Sohne", sans-serif;
  white-space: nowrap;
  flex: 0 1 0;
  height: fit-content;
}

</style>
